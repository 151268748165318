@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700&display=swap");

html {
  scroll-behavior: smooth;
}
script + iframe {
  display: none;
}
.Sara {
  font-family: Montserrat;
}

h1 {
  font-weight: 700;
}
p {
  font-weight: 500;
  font-size: 18px;
}
.BtnCntHead {
  background-color: #d5ac1e;
  color: white;
  padding: 3px 10px;
  font-weight: bolder;
  font-size: 20px;
  margin-top: 15px;
}
.BtnCnt {
  background-color: #d5ac1e;
  color: white;
  padding: 5px 20px;
  font-weight: bolder;
  font-size: 20px;
}
.bgyellow2 {
  background-color: #dfb82f;
}
.bgyellow {
  background-color: #d5ac1e;
}
.bg-brown {
  background-color: #b09874;
}
.description {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}
.bg-Water {
  background-color: #97c3c4;
}

.bg-Sky {
  background-color: #edd58f;
}
.bg-Fire {
  background-color: #b4675d;
}

/* --------------------------Nav--------------------------- */

.ico {
  color: white;
  background-color: #dd2a7b;
  font-size: 20px;
  /* padding: 0px ; */
  border-radius: 100%;
  border: 2px solid white;
  cursor: pointer;
}

.icoT {
  color: white;
  cursor: pointer;
  background-color: #00acee;
  font-size: 20px;
  /* padding: 0px ; */
  border: 2px solid white;
  border-radius: 100%;
}

.icoD {
  cursor: pointer;
  color: white;
  background-color: #36aee2;
  font-size: 20px;
  /* padding: 0px ; */
  border-radius: 100%;
  border: 2px solid white;
}

.navba {
  /* background-color: white !important; */
}
.slick-arrow {
  display: none !important;
}

.navv {
  background-color: #cc9a07 !important;
}

#mainMenu li > a {
  font-size: 12px;
  letter-spacing: 1px;
  color: #fff;
  font-weight: 400;
  position: relative;
  z-index: 1;
  text-decoration: none;
}

.main-header.fixed-nav #mainMenu li > a {
  color: #fff;
  text-decoration: none;
}

#mainMenu li:not(:last-of-type) {
  margin: 0 15px;
}

/* #mainMenu li > a::before {
  position: absolute;
  content: "";
  width: calc(100% - 1px);
  height: 1px;
  background: black;
  bottom: -6px;
  left: 0;

  -webkit-transform: scale(0, 1);
  -ms-transform: scale(0, 1);
  transform: scale(0, 1);
  -webkit-transform-origin: right center;
  -ms-transform-origin: right center;
  transform-origin: right center;
  z-index: -1;

  -webkit-transition: transform 0.5s ease;
  transition: transform 0.5s ease;
} */

#mainMenu li > a:hover::before,
#mainMenu li > a.active::before {
  -webkit-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  transform: scale(1, 1);
  -webkit-transform-origin: left center;
  -ms-transform-origin: left center;
  transform-origin: left center;
}

.main-header.fixed-nav #mainMenu li > a::before {
  background: #000;
}

.main-header {
  background-color: white;
  border-bottom: 1px solid #efefef;
  position: fixed;
  top: 0px;
  left: 0;
  z-index: 99;
  width: 100%;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.main-header.fixed-nav {
  top: 0;
  background: #fff;
  -webkit-box-shadow: 0 8px 12px -8px rgba(0, 0, 0, 0.09);
  box-shadow: 0 8px 12px -8px rgba(0, 0, 0, 0.09);
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.main-header.fixed-nav .navbar-brand > img:last-of-type {
  display: block;
}

.main-header.fixed-nav .navbar-brand > img:first-of-type {
  display: none;
}
.navbar-brand {
  color: #fff;
}
.main-header .navbar-brand img {
  max-width: 40px;
  animation: fadeInLeft 0.4s both 0.4s;
}
/* main-header end */
@media (max-width: 991px) {
  /*header starts*/

  .collapse.in {
    display: block !important;
    padding: 0;
    clear: both;
  }

  .navbar-toggler {
    margin: 0;
    padding: 0;
    width: 40px;
    height: 40px;
    position: absolute;
    top: 25px;
    right: 0;
    border: none;
    border-radius: 0;
    outline: none !important;
  }

  .main-header .navbar {
    float: none;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    text-align: center;
  }

  .main-header .navbar-nav {
    margin-top: 70px;
  }
  .size {
    font-size: 50px !important;
  }

  .main-header .navbar-nav li .nav-link {
    text-align: center;
    padding: 20px 15px;
    border-radius: 0px;
  }

  /**/
  .main-header .navbar-toggler .icon-bar {
    background-color: #c69200;
    margin: 0 auto 6px;
    border-radius: 0;
    width: 30px;
    height: 2px;
    position: absolute;
    right: 0;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }

  .main-header .navbar .navbar-toggler .icon-bar:first-child {
    margin-top: 3px;
  }

  .main-header .navbar-toggler .icon-bar-1 {
    width: 20px;
    top: 0px;
  }

  .main-header .navbar-toggler .icon-bar-2 {
    width: 26px;
    top: 12px;
  }

  .main-header .navbar-toggler .icon-bar-3 {
    width: 18px;
    top: 21px;
  }

  .main-header .current .icon-bar {
    margin-bottom: 5px;
    border-radius: 0;
    display: block;
  }

  .main-header .current .icon-bar-1 {
    width: 18px;
  }

  .main-header .current .icon-bar-2 {
    width: 30px;
  }

  .main-header .current .icon-bar-3 {
    width: 10px;
  }

  .main-header .navbar-toggler:hover .icon-bar {
    background-color: #d5ac1e;
  }

  .main-header .navbar-toggler:focus .icon-bar {
    background-color: #c69200;
  }

  /*header ends*/
}

/* --------------------------Video --------------------------- */

.scroll-spacer {
  align-items: center;
  background-color: #1a1a1a;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  height: 75vh;
  margin: 1em auto;
  width: 90%;
}
video {
  display: block;
  height: auto;
  margin: auto;
  position: relative;
  width: 100%;
}
.video-wrap {
  margin: 0 auto;
  position: relative;
  width: 90%;
}

.slick-prev::before {
  content: "\f060";
  font-weight: 900;
  position: relative;
  color: black;
  font-family: "Font Awesome 6 Free" !important;
  left: 10px !important;
}
.slick-next::before {
  content: "\f061";
  font-weight: 900;
  position: relative;
  color: black;
  font-family: "Font Awesome 6 Free" !important;
  right: 10px !important;
}
.slick-arrow {
  position: absolute;
  top: 50%;
}
.slick-list {
  overflow: hidden;
}
